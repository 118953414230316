"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    class: {
      "has-logo": _vm.showLogo
    }
  }, [_vm.showLogo ? _c("logo", {
    attrs: {
      collapse: _vm.isCollapse
    }
  }) : _vm._e(), _vm._v(" "), _c("el-scrollbar", {
    attrs: {
      "wrap-class": "scrollbar-wrapper"
    }
  }, [_c("el-menu", {
    attrs: {
      "default-active": _vm.activeMenu,
      collapse: _vm.isCollapse,
      "background-color": _vm.variables.menuBg,
      "text-color": _vm.variables.menuText,
      "active-text-color": _vm.variables.menuActiveText,
      "collapse-transition": false,
      "unique-opened": "",
      mode: "vertical"
    }
  }, _vm._l(_vm.sidebarRouters, function (route) {
    return _c("sidebar-item", {
      key: route.path,
      attrs: {
        item: route,
        "base-path": route.path
      }
    });
  }), 1)], 1)], 1);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;