"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = exports.default = {
  name: 'Doc',
  methods: {
    click: function click() {
      window.open('https://eladmin.vip/pages/010101/', '_blank');
    }
  }
};